<template>
  <div class="app-detail">
    <div class="app-title flex-start">
      <el-button @click="back" size="mini">返回</el-button>
      <div class="title">{{id && type? '应用详情' : id ? `编辑${$route.query.Propstype == 1?'自建':'三方'}应用`:`新增${$route.query.Propstype == 1?'自建':'三方'}应用`}}</div>
    </div>
    <div class="app-content">
      <div v-if="$route.query.Propstype == 2">
        <div style="margin-bottom:10px;font-weight:400;font-size:14px;font-weight:400;">1、仅支持“名冠天下”服务商的三方应用</div>
        <div style="margin-bottom:10px;font-weight:400;font-size:14px;font-weight:400;">2、三方应用工作台自定义展示默认为关闭，请先到企业微信管理后台找到该应用，开启“工作台自定义展示”。
          <span style="margin-bottom:10px;font-weight:400;font-size:14px;font-weight:400;cursor:pointer;color:#0078F0;" @click="guideVisible = true">操作指引</span>
        </div>
        
      </div>
      <el-form :model="checkForm" v-if="id && type" size="small" label-position="top" ref="checkForm" class="demo-ruleForm">
        <el-form-item label="应用名称" prop="name">
          <el-input v-model="checkForm.checkname" disabled></el-input>
        </el-form-item>
        <el-form-item label="应用ID" prop="appId">
          <el-input type="number" v-model="checkForm.checkappId" disabled></el-input>
        </el-form-item>
        <el-form-item label="设置时间" prop="create_time">
          <el-input v-model="checkForm.create_time" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input type="textarea" v-model="checkForm.checkdesc" disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="ruleForm" v-else size="small" label-position="top" v-loading="formLoading" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="应用名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="应用ID" prop="appId">
          <el-input v-model="ruleForm.appId"></el-input>
        </el-form-item>
        <el-form-item label="Secret" prop="secret" v-if="$route.query.Propstype == 1" >
          <el-input v-model="ruleForm.secret"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>

      <div class="flex-bet flex-title" v-if="id">
        <div>关联数据源</div>
        <div>
          <el-pagination
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :page-size="10"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>

      <el-table v-if="id"
          :data="tableData" size="small"
          style="width: 100%">
        <el-table-column
            prop="api_name"
            label="数据源名称"
            width="160" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="api_status"
            label="数据类型"
            width="100">
          <template slot-scope="scope">
            <span>{{scope.row.api_status===1 ? 'API接口' : scope.row.api_status===2 ? '名冠API接口' :'数据库'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="api_state"
            label="数据源接口通讯状态">
          <template slot-scope="scope">
            <span>{{scope.row.api_state===1 ? '正常' : '异常'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="create_time"
            label="添加时间"
            sortable
            width="150">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注"
             show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="btns" v-if="!type">
        <el-button v-if="id" @click="goBack" class="cancel" size="mini">取消</el-button>
        <el-button v-else @click="resetForm('ruleForm')" class="cancel" size="mini">取消</el-button>
        <el-button @click="submitForm('ruleForm')" size="mini" type="primary" :loading="loading">确定</el-button>
      </div>
    </div>
    <el-dialog title="通讯录secret" :visible.sync="dialogVisible" width="600px">
        <span style="color:#666666;">如果需要自定义自建应用，需要录入通讯录secret后才能支持</span>
        <el-input v-model="link_secret" placeholder="请输入通讯录secret" style="margin-top:20px;"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addLinkSecret">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="通讯录secret" :visible.sync="guideVisible" width="900px">
      <div style="height:600px;overflow-y: scroll;">
        <img src="@/assets/images/guide1.png" alt="" style="width:800px;">
        <img src="@/assets/images/guide2.png" alt="" style="width:800px;">
        <img src="@/assets/images/guide3.png" alt="" style="width:800px;">
      </div>
       
    </el-dialog>

  </div>
</template>

<script>
import {insertApp,getAppInfo,updateApp,addLinkSecret,getLinkSecret} from '@/api/workbench'
import { Lazy } from 'swiper'
export default {
  name: "AppDetail",
  data(){
    return{
      id:null,
      type:null,
      checkAll:false,
      loading:false,
      formLoading:false,
      ruleForm:{
        name:'',
        appId:'',
        secret:'',
        desc:'',
      },
      pageTotal:0,
      rules: {
        appId: [
          { required: true, message: '请填写应用ID', trigger: 'blur' },
        ],
        secret:[
          { required: true, message: '请填写应用secret', trigger: 'blur' },
          { min: 10, max: 70, message: '长度在 10 到 70 个字符', trigger: 'blur' }
        ]
      },
      tableData:[],
      checkForm:{
        checkname:'',
        checkappId:'',
        create_time:'',
        checkdesc:'',
      },
      params:{
        id:null,
        page:1,
        page_size:10,
      },
      dialogVisible:false,
      guideVisible:false,//操作指引显示
      link_secret:'',//录入通讯录secret
    }
  },
  created(){
    console.log(this.$route.query.Propstype,'query')
    this.id = this.$route.query.id;
    this.$route.query.id ? this.getAppDetail() :''
    this.type=this.$route.query.type;
    if(this.$route.query.Propstype == 1){
       this.getLinkSecret()
    }
  },
  methods:{
    back(){//返回
      this.$router.push('/home/appmana/index')
    },
    trimFun(s){
      return s.replace(/(^\s*)|(\s*$)/g, "");
    },
    getAppDetail(){//获取应用详情
      this.formLoading=true;
      this.params.id=this.id;
      const that=this;
      let data = this.params;
      getAppInfo(data).then(res=>{
        if(res.code===200){
          let data=res.data;
          this.tableData=data.api.data;
          that.pageTotal=parseInt(data.api.total);
          if(this.type){
            this.checkForm.checkappId=data.app_id;
            this.checkForm.checkname=data.app_name;
            this.checkForm.create_time=data.create_time;
            this.checkForm.checkdesc=data.remarks;
          }else{
            this.ruleForm.appId=data.app_id;
            this.ruleForm.name=data.app_name;
            this.ruleForm.secret=data.app_secert;
            this.ruleForm.desc=data.remarks;
          }

        }
        this.formLoading=false;
      }).catch(err=>{
        this.formLoading=false;
        console.log(err)
      })
    },
    submitForm(formName){//添加应用
      
      const {name,appId,secret,desc}=this.ruleForm
      let flag=true;
      const that=this;
      const allNum=/^\d+$/;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(typeof (appId) == 'string'){
            if(that.trimFun(appId).length<7 || that.trimFun(appId).length>10 || !allNum.test(that.trimFun(appId))){
              flag=false;
              that.$message({
                type:'warning',
                message:'应用ID请输入7至10位数字'
              })
              that.loading=false;
              return
            }
          }
          if(secret.trim().length<10 && this.$route.Propstype == 1){
            that.$message({
              type:'warning',
              message:'应用Secret输入不少于10位'
            })
            flag=false;
            return
          }
          if(flag){
            const data={
              app_id:typeof (appId)=='number' ? appId : parseInt(that.trimFun(appId)),
              app_name:name.trim(),
              app_secert:secret.trim(),
              remarks:desc.trim(),
              type:this.$route.query.Propstype
            }
            if(that.id){
              data.id=parseInt(that.id);
              updateApp(data).then(res=>{
                if(res.code===200){
                  that.$message({
                    type: 'success',
                    message: '编辑应用成功!'
                  });
                  setTimeout(()=>{
                    that.$router.push('/home/appmana/index')
                    that.loading=false;
                  },2000)
                }
                if(res.code==10006){
                   this.guideVisible = true
                }
              }).catch(err=>{
                
                console.log(err)
                that.loading=false;
              })

            }else{
              insertApp(data).then(res=>{
                if(res.code==200){
                  that.$message({
                    type: 'success',
                    message: '创建应用成功!'
                  });
                  setTimeout(()=>{
                    that.$router.push('/home/appmana/index')
                    that.loading=false;
                  },2000)
                }
                if(res.code==10006){
                  this.guideVisible = true
                }
              }).catch(err=>{
                
                console.log(err)
                that.loading=false;
              })
            }
          }else{
            this.$message({
              type:'warning',
              message:'请正确填写表单'
            })
            this.loading=false;
            return false
          }


        } else {
          that.loading=false;
          return false;
        }

      });
    },
    // openInputSecret(){
    //   this.dialogVisible = true
    //   this.loading=false;
    // },
    getLinkSecret(){
      getLinkSecret().then(res=>{
        console.log(res,'res')
        if(res.code == 200){
          if(!res.data){
              this.dialogVisible = true
          }
        }
      }).catch(err=>{
          this.$message({message:res.message,type: 'warning'});
      })
    },
    addLinkSecret(){
      // if(this.link_secret == ''){
      //   this.$message({message: '请先输入录入通讯录secret',type: 'warning'});
      // } 
      addLinkSecret({link_secret:this.link_secret}).then(res=>{
          if(res.code == 200){
            this.dialogVisible = false
            this.link_secret  = ''
            this.$message({
              type: 'success',
              message: '录入成功!'
            });
          }
      }).catch(err=>{
          this.$message({message:res.message,type: 'warning'});
      })
    },
    resetForm(formName){//取消
      this.$refs[formName].resetFields();
    },
    goBack(){
      this.$router.push('/home/appmana/index')
    },
    handleCurrentChange(page){//分页
      console.log(page)
      this.params.page=page;
      this.getAppDetail()

    }
  }
}
</script>

<style lang="less" scoped>
.app-detail{
  .app-title{
    padding: 0 24px;
    height: 48px;
    background: #F8F9FA;
    border-bottom: 1px solid #DBDBDB;
    .title{
      flex:1;
      text-align: center;
    }
  }
  /deep/.el-form-item__label{
    padding:0 0 2px;
  }
  .app-content{
    padding: 44px 240px;
    .flex-title{
      font-size: 14px;
      margin:20px 0 12px;
    }
    /deep/.el-table{
      /deep/thead{
        /deep/th{
          padding:2px 0;
          font-weight: 400;
          color:#606266;
        }
        /deep/tr,td,th{
          background-color: #DFE9F5!important;
        }
      }
    }
    .btns{
      padding-top: 22px;

    }
  }
}
</style>